import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { EventListTable } from "./EventListTable";
export const CurrentDayEventList = ({events, onDelete, updateDates}) => {
    const [filter,setFilter] = useState(3);
    const [direction,setDirection] = useState(true);
    const navigate = useNavigate();

    const curr = new Date();
    const date = `${curr.getMonth()+1}/${curr.getDate()}/${curr.getFullYear()}`
    
    const sortedEvents = direction ? events.slice().sort((a,b) => new Date(b.date) - new Date(a.date)) : events.slice().sort((a,b) => new Date(a.date) - new Date(b.date))

    const allEvents = sortedEvents.map((e, index) => {
        const gameDate = new Date(e.date)
        const displayR = <tr key={index} style={{height:"75px"}}>
                            <td>{e.date}</td>
                            <td><div style={{maxHeight:"90px",overflowY:"auto",wordWrap:"break-word"}}>{e.title}</div></td>
                            {e.game ? <td style={{backgroundImage:e.game && e.game.cover && `linear-gradient(to left, rgb(0, 0, 0,0.1), rgba(0,0,0,0.6) 60%, black),url(//images.igdb.com/igdb/image/upload/t_1080p/${e.game.cover.image_id}.jpg)`, backgroundRepeat:"no-repeat", backgroundSize:"100%", backgroundPosition:"center"}}>
                                <a onClick={()=>navigate("/game-details/"+e.game.id, {state: e.game.id})} style={{display:"flex",alignItems:"center", cursor:"pointer"}}>
                                    <div style={{paddingLeft:"10px"}}>{e.game.name}</div>
                                </a>
                            </td> : <td style={{height:"90px"}}>No Game Added</td>}
                            <td><button className="btn btn-danger" onClick={()=>{onDelete(e.title)}}>Delete</button></td>
                        </tr>

        switch(filter) {
            case 0:
                if (e.date === date) return(displayR);
                break;
            case 1:
                return(displayR);
            case 2:
                if (gameDate.getFullYear() === curr.getFullYear()) return(displayR);
                break;
            case 3:
                if (gameDate.getMonth() === curr.getMonth() && gameDate.getFullYear() === curr.getFullYear()) return(displayR);
                break;
        }
    })

    return (
        <>
        <div style={{width:"80%", margin:"auto",paddingTop:"10px"}}>
            <div style={{display:"grid",gridTemplateColumns:"25% 55% 15%",columnGap:"20px",paddingBottom:"10px"}}>
                <button className="btn btn-primary" onClick={updateDates}>Update Release Dates</button>
                <div className="btn-group"  aria-label="eventListBtns">
                <input type="radio" className="btn-check" name="btnradio" id="btnradio1" autoComplete="off" onClick={() => setFilter(1)} />
                <label className="btn btn-primary" htmlFor="btnradio1">All Events</label>

                <input type="radio" className="btn-check" name="btnradio" id="btnradio2" autoComplete="off" onClick={() => setFilter(2)}  />
                <label className="btn btn-primary" htmlFor="btnradio2">This Year</label>

                <input type="radio" className="btn-check" name="btnradio" id="btnradio3" autoComplete="off" onClick={() => setFilter(3)} defaultChecked={true} />
                <label className="btn btn-primary" htmlFor="btnradio3">This Month</label>

                <input type="radio" className="btn-check" name="btnradio" id="btnradio4" autoComplete="off" onClick={() => setFilter(0)} />
                <label className="btn btn-primary" htmlFor="btnradio4">Today</label>
                </div>
                <div className="btn-group"  aria-label="eventListSortBtns">
                <input type="radio" className="btn-check" name="sortBtn" id="desc" autoComplete="off" onClick={() => setDirection(false)} />
                <label className="btn btn-primary" htmlFor="desc">Desc</label>

                <input type="radio" className="btn-check" name="sortBtn" id="asc" autoComplete="off" onClick={() => setDirection(true)} defaultChecked={true} />
                <label className="btn btn-primary" htmlFor="asc">Asc</label>
                </div>
            </div>
            {allEvents.filter(e => e == undefined).length !== allEvents.length ? <EventListTable eventArray={allEvents} /> : <div style={{fontSize:"30px",color:"white",textAlign:"center",textShadow:"1px 1px 1px black"}}>No Events Happening</div>}
        </div>
        </>
    )
}

export default CurrentDayEventList;