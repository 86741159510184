import { useState } from 'react';
import { Outlet, NavLink, useNavigate } from 'react-router-dom';
import { SearchBar } from './SearchBar';
import logo from './../Utils/calendarHomeIcon.jpg'

export const Nav = ({userData, onUserListSave, onUserListDel, events, onEventSave}) => {
    const navigate = useNavigate();
    const [filteredData, setFilteredData] = useState([]);
    const [wordEntered, setWordEntered] = useState("");
    const clearInput = () => {
        setFilteredData([]);
        setWordEntered("");
    }

    return (
        <>
            <nav className="navbar bg-dark navbar-expand-lg bg-body-tertiary" data-bs-theme="dark" style={{padding:"0px"}}>
                    <ul className="navbar-nav mb-2 mb-lg-0">
                        <li className="nav-item testing" style={{width:"200px",fontSize:"30px",color:"white", display:"flex", marginLeft:"25px",cursor:"pointer",textShadow:"2px 2px 5px #a760e9"}} onClick={()=>navigate("/")}>
                            <div style={{position:"absolute",top:"10px",left:"30px"}}>EventH</div>
                            <img src={logo} width="32%" style={{marginLeft:"75px"}} />
                            <div style={{position:"absolute",top:"10px",left:"148px"}}>rizon</div>
                        </li>
                        <li className="nav-item" style={{marginTop:"10px"}}>
                            <NavLink to="/" onClick={clearInput} id="navLinkColor" className="nav-link">Home</NavLink>
                        </li>
                        <li className="nav-item" style={{marginTop:"10px"}}>
                            <NavLink to="user-profile" onClick={clearInput} id="navLinkColor" className="nav-link">Game Lists</NavLink>
                        </li>
                        <li className="nav-item" style={{marginTop:"10px"}}>
                            <NavLink to="search-results/all" onClick={clearInput} id="navLinkColor" className="nav-link">Find Games</NavLink>
                        </li>
                    </ul>
                    <div style={{marginLeft:window.innerHeight > 1200 ? "14.5vw" : "6.5vw"}}>
                        <SearchBar 
                            check={false}
                            onUserListSave={onUserListSave}
                            onUserListDel={onUserListDel}
                            userData={userData}
                            clearInput={clearInput}
                            filteredData={filteredData}
                            setFilteredData={e => setFilteredData(e)}
                            wordEntered={wordEntered}
                            setWordEntered={e => setWordEntered(e)}
                            events={events}
                            onEventSave={onEventSave}
                        />
                    </div>
            </nav>
            <div style={{maxHeight:"95vh",overflowY:"auto"}}>
                <Outlet />
            </div>
        </>
    )
}

export default Nav;