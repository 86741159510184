export const numToRoman = (name) => {
    let nameSplit = name.split(" ")
    let endString = ''
    if(!Number.isNaN(nameSplit[nameSplit.length-1])) {
        let num = nameSplit[nameSplit.length-1]
        var roman = {
            x: 10,
            ix: 9,
            v: 5,
            iv: 4,
            i: 1
          };
          var str = '';
        
          for (var i of Object.keys(roman)) {
            var q = Math.floor(num / roman[i]);
            num -= q * roman[i];
            str += i.repeat(q);
          }
          nameSplit[nameSplit.length-1] = str
        endString = nameSplit.join(" ")
    }
    return endString
}