import React from 'react';

export const Day = ({ day, onClick }) => {
  const className = `day ${day.value === 'padding' ? 'padding' : ''} ${day.isCurrentDay ? 'currentDay' : ''}`;
  const threeEvents = !day.event ? '' : day.event.slice(0,3).map((e, index) => <div key={index} className='event'>{e.title}</div>);
  const eventCount = day.event === null ? '' : day.event.length;
  const eventImage = day.event && day.event.find(e => e.game != null);

  return (
    <div onClick={onClick} className={className} style={eventImage && {backgroundImage:`url(//images.igdb.com/igdb/image/upload/t_1080p/${eventImage.game.cover.image_id}.jpg)`, backgroundRepeat:"no-repeat", backgroundSize:"100% 100%"}}>
      {day.value === 'padding' ? '' : <p style={{textShadow:"1px 1px 3px black"}}>{day.value}</p>}
      {day.event && eventCount<4 && eventCount>0 && <div>{threeEvents}</div>}
      {day.event && eventCount>=4 && <div className='event'>{eventCount} Events</div>}
    </div>
  );
};

export default Day;