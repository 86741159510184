import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { dataMapping } from './apiDataMapping';
import { UserListButtons } from '../Components/UserList/UserListButtons';

export const useSearchResults = (details, dataFilter, itemOffset,userData, onUserListDel, onUserListSave, events, onEventSave, onEventDel, listAmount) => {
    const navigate = useNavigate()
    const [tempDetails,setTempDetails] = useState([])
    const [pageCount,setPageCount] = useState(1)
    const [currentPageDetails,setCurrentPageDetails] = useState([])
    const gamePassData = localStorage.getItem("gamePassData") ? JSON.parse(localStorage.getItem("gamePassData")).data : [];
    const [showGameCarousel,setShowGameCarousel] = useState(false);
    const [gameScreenshots,setGameScreenshots] = useState(null);

    const saveEvent = (title,game,date) => {
        const dateChange = `${date.getMonth()+1}/${date.getDate()}/${date.getFullYear()}`
        onEventSave([ ...events, { title: title+" Release Date", game: game, date: dateChange }])
    }
    const delEvent = (title) => { onEventDel(events.filter(e => e.game.name !== title)) }
    const toggleCarousel = (id) => {
        if(id == 0) {
            setShowGameCarousel(false)
        } else {
            let g = details.filter(e => e.id == id)[0];
            setGameScreenshots(g.screenshots ? g.screenshots.map((img, index) => <div key={index}><img src={`//images.igdb.com/igdb/image/upload/t_screenshot_huge/${img.image_id}.jpg`}></img></div>) : null)
            setShowGameCarousel(true)
        }
    }

    useEffect(() => {
        if(details && details[0] === "No Results Found") {setCurrentPageDetails(details); setPageCount(1)}
        let endOffset = itemOffset + listAmount;
        let currentDetails = []
        if(details) {
            let sortedDetails = details.slice();
            if (dataFilter) {
                sortedDetails.sort((a,b) => {
                    if (dataFilter === "aggregated_rating" || dataFilter === "first_release_date") {
                        if((a[dataFilter] ? a[dataFilter] : 0) > (b[dataFilter] ? b[dataFilter] : 0)) return -1
                    } else if (dataFilter === "hltbReview") {
                        if((a["userReview"] ? a["userReview"][dataFilter] : 0) > (b["userReview"] ? b["userReview"][dataFilter] : 0)) return -1
                    } else {
                        if((a[dataFilter] ? a[dataFilter] : Number.MAX_VALUE) < (b[dataFilter] ? b[dataFilter] : Number.MAX_VALUE)) return -1
                    }
                })
            } else {
                sortedDetails.sort((a,b) => {if((a["first_release_date"] ? a["first_release_date"] : 0) > (b["first_release_date"] ? b["first_release_date"] : 0)) return -1})
            }
            currentDetails = sortedDetails.slice(itemOffset, endOffset)
            setPageCount(Math.ceil(sortedDetails.length / listAmount))
        }
        setCurrentPageDetails(currentDetails.map((e,index) => {
            const gameModes = dataMapping(e,"game_modes",navigate)
            const gameGenres = dataMapping(e,"genres",navigate)
            const gamePerspectives = dataMapping(e,"player_perspectives",navigate)
            const gamePlatforms = dataMapping(e,"platforms",navigate)
            const gameReleaseDate = e.first_release_date ? new Date(e.first_release_date*1000) : null
            const gameThemes = dataMapping(e,"themes",navigate)
            const gamePassDataFound = gamePassData?.find((game) => game.title.replace(/[^\x00-\x7F]/g, "").toLowerCase().includes(e.name.replace(/[^\x00-\x7F]/g, "").toLowerCase()));
            return(
                <div key={index} className="sResultItem" onMouseEnter={()=>toggleCarousel(e.id)} onMouseLeave={()=>toggleCarousel(0)}>
                    <div style={{marginLeft:"15px", display:"grid",gridTemplateColumns:"25% auto 40%",columnGap:"15px",height:"100%"}}>
                        <div>
                            <div className="gameCover" style={{fontSize:"18px",fontWeight:"600"}} onClick={() => navigate("/game-details/"+e.id)}>
                                {e.cover ? <img className="gameCoverImg" src={`//images.igdb.com/igdb/image/upload/t_cover_big/${e.cover.image_id}.jpg`} width="100%" height="275px" /> : <img className="gameCoverImg" style={{width:"100%"}} src="https://t4.ftcdn.net/jpg/02/51/95/53/360_F_251955356_FAQH0U1y1TZw3ZcdPGybwUkH90a3VAhb.jpg" width="100%" height="275px" />}
                                <div className="gameCoverName">
                                    {gamePassDataFound?.type === "ea" && <div className="thirdPartyBanner" style={{backgroundImage:"linear-gradient(270deg, #e52a35, #0d1041"}}>On EA Play</div>}
                                    {gamePassDataFound?.type === "xbox" && <div className="thirdPartyBanner" style={{backgroundColor:"#0e7a0d"}}>On GamePass</div>}
                                    {e.name}
                                </div>
                            </div>
                            {gameReleaseDate >= new Date() && !events.some(eventGame => eventGame.game && eventGame.game.name === e.name) && <div style={{textAlign:"center"}} >
                                                                                                                        <button className="btn btn-primary btn-sm" onClick={() => saveEvent(e.name,e,gameReleaseDate)}>Add to Calendar</button>
                                                                                                                    </div>}
                            {events.some(eventGame => eventGame.game ? eventGame.game.name === e.name : null) && <div style={{textAlign:"center"}}>
                                                                                                                    <button className="btn btn-danger btn-sm" onClick={() => delEvent(e.name)}>Remove from Calendar</button>
                                                                                                                </div>}
                        </div>
                        {/* when adding price info for steam and xbox - probably put it above the gameinfo */}
                        <div style={{fontSize:"14px",fontWeight:"600",overflowY:"auto",height:"295px"}}>
                            {gamePlatforms && <div>
                                                <div style={{fontWeight:"700"}}>Platforms</div>
                                                <div style={{paddingLeft:"10px"}}>{gamePlatforms}</div>
                                            </div>}
                            {gameReleaseDate && <div>
                                                    <div style={{fontWeight:"700"}}>Release Date</div>
                                                    <div style={{paddingLeft:"10px"}}>{gameReleaseDate.toDateString()}</div>
                                                </div>}
                            {gameModes && <div>
                                            <div style={{fontWeight:"700"}}>Game Modes</div>
                                            <div style={{paddingLeft:"10px"}}>{gameModes}</div>
                                        </div>}
                            {gameGenres && <div>
                                            <div style={{fontWeight:"700"}}>Genres</div>
                                            <div style={{paddingLeft:"10px"}}>{gameGenres}</div>
                                        </div>}
                            {gamePerspectives && <div>
                                                    <div style={{fontWeight:"700"}}>Player Perspectives</div>
                                                    <div style={{paddingLeft:"10px"}}>{gamePerspectives}</div>
                                                </div>}
                            {gameThemes && <div>
                                                <div style={{fontWeight:"700"}}>Themes</div>
                                                <div style={{paddingLeft:"10px"}}>{gameThemes}</div>
                                            </div>}
                        </div>
                        <div style={{display:"grid",alignContent:"center"}}>
                            {gameReleaseDate < new Date() && <div style={{display:"grid",textAlign:"center",gridTemplateColumns:"50% 50%",fontWeight:"600",justifyContent:"space-evenly"}}>
                            <div>
                                <div style={{fontSize:(e.userReview || gamePassDataFound) ? "26px" : "20px"}}>{e.userReview ? Math.round(e.userReview.hltbReview)+"%" : gamePassDataFound ? gamePassDataFound.userRating+"/5.0" : "No User Reviews Found"}</div>
                                {(e.userReview || gamePassDataFound) && <div>{e.userReview ? e.userReview.hltbReviewCount : gamePassDataFound ? "Xbox" : ""} User Reviews</div>}
                            </div>
                            {(e.aggregated_rating && e.aggregated_rating_count) ? <div>
                                                                                    <div style={{fontSize:"26px"}}>{Math.round(e.aggregated_rating)}%</div>
                                                                                    <div>{e.aggregated_rating_count} Critic Reviews</div>
                                                                                </div> : <div style={{fontSize:"20px"}}>No Critic Reviews Found</div>}
                            </div>}
                            <div>
                                <UserListButtons 
                                    game={e}
                                    searchBar={false}  
                                    searchResult={true}  
                                    onUserListSave={onUserListSave}
                                    onUserListDel={onUserListDel}
                                    userData={userData}
                                    events={events}
                                    onEventSave={onEventSave}
                                    gamePassData={gamePassDataFound}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            )
        }))
    }, [details, dataFilter, itemOffset, listAmount, events, userData])

    return {
        currentPageDetails, 
        pageCount,
        gameScreenshots,
        showGameCarousel
    };
};