import { MenuItem, Select } from "@mui/material";
import { useEffect, useState } from "react";

export const Countdown = ({events, selectedEvent, addCountdown}) => {
    const [time, setTime] = useState(0);
    const [display,setDisplay] = useState();

    useEffect(() => {
        setTime(selectedEvent && (new Date(selectedEvent.date).getTime() - new Date().getTime())/1000)
    },[selectedEvent])

    useEffect(() => {
        if(selectedEvent) {
            if(time < 0) return;
            let timer = setInterval(() => {
                setTime((time) => 
                   time - 1
                );
              }, 1000);
        
              return () => {
                clearInterval(timer);
              }
        }
    }, [time]);

    useEffect(() => {
        let stringTime = selectedEvent && time >= 0 ? `${Math.floor(time/86400)}`.padStart(3, 0) + ":" + `${Math.floor((time/3600) % 24)}`.padStart(2, 0) + ":" + `${((time/60) % 60).toFixed(0)}`.padStart(2, 0) + ":" + `${(time % 60).toFixed(0)}`.padStart(2, 0) : selectedEvent ? "RELEASED!!!" : "000:00:00:00";
        setDisplay(stringTime.split("").map((e,ind) => 
            <div key={ind} style={{width:"25px",backgroundColor:"rgba(122, 122, 122, 0.35)", fontSize:"45px", textAlign:"center",borderRadius:"5px"}}>
                {e}
            </div>))
    },[time])

    return(
        <div style={{backgroundColor:"black",borderRadius:"10px", paddingLeft:"10px", paddingRight:"5px", color:"white",backgroundImage:selectedEvent && selectedEvent.game && selectedEvent.game.cover && `linear-gradient(to left, rgb(0, 0, 0,0.1), rgba(0,0,0,0.6), black),url(//images.igdb.com/igdb/image/upload/t_1080p/${selectedEvent.game.cover.image_id}.jpg)`, backgroundRepeat:"no-repeat", backgroundSize:"100%", backgroundPosition:"0% 20%"}}>
            <div style={{height:"7vh", alignContent:"center"}}>
                {events && selectedEvent != 0 && 
                    <div style={{fontSize:"20px",textShadow:"1px 1px 1px #a760e9"}}>
                        {new Date(selectedEvent.date).toUTCString()}
                    </div>
                }
                <div style={{display:"flex", gap:"3px",textShadow:"1px 1px 1px #a760e9"}}>
                    {display}
                </div>
            </div>
            {events &&
                <div>
                    <br />
                    <Select value={selectedEvent ? selectedEvent.title : "default"} onChange={(e)=> addCountdown(e.target.value)} sx={{width:"340px",color: "white", '.MuiSvgIcon-root ': {fill: "white !important",}}} size="small">
                        <MenuItem value={"default"}>--Select Countdown Event--</MenuItem>
                        {events.filter((e) => new Date(e.date) > new Date() && new Date(e.date).getMilliseconds() < 31556952000).sort((a,b) => a.title.localeCompare(b.title)).map((e, ind) => <MenuItem key={ind} value={e.title}>{e.title}</MenuItem>)} 
                    </Select>
                </div>
            }
        </div>
    )
}

export default Countdown;