import React, { useState } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { UserListButtons } from './UserList/UserListButtons';
import { matchSorter } from 'match-sorter';
import { numToRoman } from '../Utils/utilMethods';

export const SearchBar = ({userData, onUserListDel, onUserListSave, check, clearInput, wordEntered, setWordEntered, filteredData, setFilteredData, addGame, place, events, onEventSave}) => {
    const navigate = useNavigate();
    const [addConfirm, setAddConfirm] = useState("");
    const gamePassData = localStorage.getItem("gamePassData") ? JSON.parse(localStorage.getItem("gamePassData")).data : [];

    const handleFilter = (event) => {
        const searchWord = event.target.value;
        setWordEntered(searchWord);
        if(searchWord === "") {
            setFilteredData([]);
        } else if (searchWord.length % 2 === 0 || searchWord.length > 4) {
          const romanString = numToRoman(searchWord)
          let searchString = `name~\"`+searchWord.toLowerCase()+`\"*`
          if(romanString.length > 0) searchString = searchString + ` | name~\"`+romanString.toLowerCase()+`\"*`
            axios({
            url: "https://game-cal-cors.onrender.com/https://api.igdb.com/v4/games",
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Client-ID': JSON.parse(localStorage.getItem('client_id')),
                'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('access_token'))
            },
                data: `fields aggregated_rating,aggregated_rating_count,category,name,cover.*,first_release_date,game_modes.name,genres.name;where cover != null & category = 0 & version_parent = null & (`+searchString+`);`
            })
            .then(response => {
                if(response.data.length > 0) {
                  let refinedSearch = matchSorter(response.data,searchWord.toLowerCase(),{keys: ['name']});
                  if(romanString.length > 0) {
                    let romanSearch = matchSorter(response.data,romanString.toLowerCase(),{keys: ['name']})
                    refinedSearch = refinedSearch.concat(romanSearch)
                  }
                  setFilteredData(refinedSearch);
                }
            })
            .catch(err => {
                console.error(err);
            });
        }
    }

    const searchStep = (game) => {
      if(check) {
        clearInput()
        addGame(game)
        setAddConfirm(game.name+" Added to Event")
      } else {
        navigate("/game-details/"+game.id)
        clearInput()
        window.location.reload(false)
      }
    }

    const barWidth = place ? {width:"220px"} : {width:"35vw"} 

    return (
      <div id="searchContainer">
        <div style={{display:"flex"}}>
          <form className="d-flex" role="search">
            <input
              className="sBarW"
              type="text"
              placeholder="Enter game name..."
              value={wordEntered}
              onChange={handleFilter}
              onKeyDown={(e) => {if (e.key === "Enter" && wordEntered) { navigate("/search-results/"+encodeURIComponent(wordEntered), {state: {type:"name"}}); window.location.reload(false);}}}
              style={barWidth}
            />
          </form>
          <button onClick={clearInput} style={{marginLeft:"2px",borderRadius:"20px"}} className="btn btn-danger">Clear</button>
        </div>
        {filteredData && filteredData.length == 0 && wordEntered.length > 0 && <div id="searchDropdown" style={barWidth}>Loading...</div>}
        {filteredData && filteredData.length != 0 && (
          <div id="searchDropdown" style={barWidth}>
            {filteredData.slice(0, 5).map((e, index) => {
              const gamePassDataFound = gamePassData?.find((game) => game.title.replace(/[!'.,-:]/g, "").toLowerCase().includes(e.name.replace(/[!'.,-:]/g, "").toLowerCase()));
              return (
                <div key={index} id="sResults" style={{gridTemplateColumns: !check ? "auto 25%" : "auto"}}>
                  <a onClick={()=>searchStep(e)} style={{display:"grid",gridTemplateColumns:"auto auto",justifyContent:"start",columnGap:"5px"}}>
                    {e.cover ? <img src={e.cover.url} style={{borderRadius:"12px"}} /> : <img className="gameCoverImg" style={{width:"100%"}} src="https://t4.ftcdn.net/jpg/02/51/95/53/360_F_251955356_FAQH0U1y1TZw3ZcdPGybwUkH90a3VAhb.jpg" width="130px" height="185px" />}
                    <span style={{margin:"auto"}}>{e.name}</span>
                  </a>
                  {!check &&
                    <div style={{margin:"auto"}}>
                      <UserListButtons
                        game={e}
                        onUserListSave={onUserListSave}
                        onUserListDel={onUserListDel}
                        userData={userData}
                        searchBar={true}
                        events={events}
                        onEventSave={onEventSave}
                        gamePassData={gamePassDataFound}
                      />
                    </div>
                  }
                </div>
              );
            })}
          </div>
        )}
        {addConfirm && <div>{addConfirm}</div>}
      </div>
    );
};

export default SearchBar;