import { useEffect, useRef, useState } from 'react';
import axios from 'axios';
import { matchSorter } from 'match-sorter';
import { numToRoman } from '../Utils/utilMethods';

export const getGameData = (gameId) => {
    const [details, setDetails] = useState(null);
    const testRef = useRef(false);
    let search = ""
    isNaN(gameId) ? search = `where name=\"`+gameId+`\";` : search = `where id=`+gameId+`;`
    useEffect(()=>{ 
        axios({
        url: "https://game-cal-cors.onrender.com/https://api.igdb.com/v4/games",
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Client-ID': JSON.parse(localStorage.getItem('client_id')),
            'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('access_token'))
        },
            data: `fields aggregated_rating,aggregated_rating_count,artworks.*,bundles.name,bundles.cover.*,category,collection.name,cover.*,dlcs.name,dlcs.cover.*,expansions.name,expansions.cover.*,
                   first_release_date,franchises.name,game_engines.name,game_modes.name,genres.name,involved_companies.company.name,involved_companies.developer,involved_companies.porting,
                   involved_companies.publisher,involved_companies.supporting,keywords.name,multiplayer_modes.*,name,parent_game.name,parent_game.cover.*,player_perspectives.name,
                   release_dates.date,release_dates.platform.name,remakes.name,remakes.cover.*,remasters.name,remasters.cover.*,screenshots.*,similar_games.name,similar_games.cover.*,standalone_expansions.name,
                   standalone_expansions.cover.*,storyline,summary,themes.name,videos.name,videos.video_id,websites.category,websites.url; `+search
        })
        .then(response => {
            testRef.current = true;
            setDetails(response.data[0]);
        })
        .catch(err => {
            console.error(err);
        });
    },[])

    return {
        details,
        testRef
    };
}

export const getSearchData = (search, releaseTime, details, setDetails, andOrCheck) => {
    const testRef = useRef(false);
    let requestData = ``
    let gameModesGroup = ``
    let genresGroup = ``
    let playerPersGroup = ``
    let platformsGroup = ``
    let themesGroup = ``
    let searchWord = search[0].search ? search[0].search.toString().toLowerCase() : ``
    let sort = ``
    useEffect(()=>{ 
        search.map((e) => {
            const searchCount = search.filter((item)=>item.type === e.type).length;
            let exclusiveCheck = ``;
            switch(e.type.toString()) {
                case "franchises":
                    requestData === `` ? requestData = `franchises=`+e.search.toString() : requestData += ` & franchises=`+e.search.toString()
                    break;
                case "game_modes":
                    gameModesGroup = gameModesGroup === `` ? gameModesGroup = e.search.toString() : gameModesGroup += `,`+e.search.toString()
                    exclusiveCheck = searchCount > 1 ? `[`+gameModesGroup+`]` : `{`+gameModesGroup+`}`
                    requestData === `` ? requestData = `game_modes=`+exclusiveCheck : requestData += ` & game_modes=`+exclusiveCheck
                    break;
                case "genres":
                    genresGroup = genresGroup === `` ? genresGroup = e.search.toString() : genresGroup += `,`+e.search.toString()
                    requestData === `` ? requestData = `genres=(`+genresGroup+`)` : requestData += ` & genres=(`+genresGroup+`)`
                    break;
                case "keywords":
                    requestData === `` ? requestData = `keywords=[`+e.search.toString()+`]` : requestData += ` & keywords=[`+e.search.toString()+`]`
                    break;
                case "platforms":
                    platformsGroup = platformsGroup === `` ? platformsGroup = e.search.toString() : platformsGroup += `,`+e.search.toString()
                    exclusiveCheck = searchCount > 1 ? andOrCheck == 2 ? `[`+platformsGroup+`]` : `(`+platformsGroup+`)` : `{`+platformsGroup+`}`
                    requestData === `` ? requestData = `platforms=`+exclusiveCheck : requestData += ` & platforms=`+exclusiveCheck
                    break;
                case "player_perspectives":
                    playerPersGroup = playerPersGroup === `` ? playerPersGroup = e.search.toString() : playerPersGroup += `,`+e.search.toString()
                    requestData === `` ? requestData = `player_perspectives=[`+playerPersGroup+`]` : requestData += ` & player_perspectives=[`+playerPersGroup+`]`
                    break;
                case "name":
                    if(e.search.toString() === "all") {
                        requestData = `first_release_date > `+(Math.floor(new Date().setFullYear(new Date().getFullYear() - 1)/1000)).toString()+` & first_release_date < `+(Math.floor(new Date().setFullYear(new Date().getFullYear() + 1)/1000)).toString()
                        sort = `sort first_release_date desc;`
                    } else {
                        const romanString = numToRoman(e.search.toString().toLowerCase())
                        if(romanString.length > 0) {
                            requestData === `` ? requestData = `(name~*\"`+e.search.toString().toLowerCase()+`\"* | name~*\"`+romanString+`\"*)` : requestData += ` & (name~*\"`+e.search.toString().toLowerCase()+`\"* | name~*\"`+romanString+`\"*)`
                        }
                        else {
                            requestData === `` ? requestData = `name~*\"`+e.search.toString().toLowerCase()+`\"*` : requestData += ` & name~*\"`+e.search.toString().toLowerCase()+`\"*`
                        }
                        
                    }
                    break;
                case "themes":
                    themesGroup = themesGroup === `` ? themesGroup = e.search.toString() : themesGroup += `,`+e.search.toString()
                    requestData === `` ? requestData = `themes=(`+themesGroup+`)` : requestData += ` & themes=(`+themesGroup+`)`
                    break;
        }})
        if(releaseTime > 0) {
            requestData === `` ? requestData = `first_release_date > `+(Math.floor(new Date()/1000)).toString()+` & first_release_date < `+(Math.floor(new Date().setMonth(new Date().getMonth() + releaseTime)/1000)).toString() : requestData += ` & first_release_date > `+(Math.floor(new Date()/1000)).toString()+` & first_release_date < `+(Math.floor(new Date().setMonth(new Date().getMonth() + releaseTime)/1000)).toString()
        }
        if(releaseTime === -1) {
            requestData === `` ? requestData = `first_release_date <= `+(Math.floor(new Date()/1000)).toString() : requestData += ` & first_release_date <= `+(Math.floor(new Date()/1000)).toString();
        }
        sort = `sort first_release_date desc;`

        axios({
            url: "https://game-cal-cors.onrender.com/https://api.igdb.com/v4/games",
            method: 'POST',
            headers: {
                'Accept': 'application/json',
                'Client-ID': JSON.parse(localStorage.getItem('client_id')),
                'Authorization': 'Bearer '+JSON.parse(localStorage.getItem('access_token'))
            },
                data: `fields aggregated_rating,aggregated_rating_count,cover.*,first_release_date,game_modes.name,genres.name,name,player_perspectives.name,
                    platforms.name,screenshots.*,themes.name; limit 500; `+sort+` where (`+requestData+`);`
            })
            .then(response => {
                let respCopy = response.data.slice()
                // respCopy.map((item,index) => {
                //     fetch(`https://shouldiplay-api.up.railway.app/hltb/${encodeURIComponent(item.name.toLowerCase())}`)
                //     .then((resp) => resp.json())
                //     .then((result) => {
                //         if (result.data.length > 0) {
                //             result.data.map((e)=>{
                //                 if(e.game_name.toLowerCase() === item.name.toLowerCase()) item["userReview"] = {hltbReview:e.review_score,hltbReviewCount:e.count_review}
                //             })
                //         }
                //         if (index === response.data.length-1) {
                //             testRef.current = true;
                //             search.type === "name" && searchWord !== "all" ? setDetails(matchSorter(respCopy,searchWord,{keys: ['name']})) : setDetails(respCopy)
                //         }
                //     })
                // })
                testRef.current = true;
                search.type === "name" && searchWord !== "all" ? setDetails(matchSorter(respCopy,searchWord,{keys: ['name']})) : setDetails(respCopy)
            })
            .catch(err => {
                console.error(err);
        });
    },[search, releaseTime, andOrCheck])

    return {
        details,
        testRef
    };
}